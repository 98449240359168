import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { AuthenticationService } from './services/authentication.service';
import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {

  loginStatus = false;

  // ICON info: https://ionicons.com/cheatsheet.html
  // ICON info official: https://ionicons.com/

  public appUserPages = [
    { title: 'Actividades en curso', url: '/users/tasks', icon: 'calendar' },
    { title: 'Asignaturas', url: '/users/classes', icon: 'switch' },
    { title: 'Grados', url: '/users/degrees', icon: 'school' },
    { title: 'Configuración', url: '/users/settings', icon: 'settings' },
    { title: 'Perfíl', url: '/users/profile', icon: 'contact' },
    { title: 'Sugerencias', url: '/users/suggestions', icon: 'bulb' },
    { title: 'Acerca de [+log]', url: '/users/log-info', icon: 'information-circle' }
    /*,
    { title: 'Invitame a un cafe', url: '/users/coffee-break', icon: 'cafe' }
    */
  ];
  public appPublicPages = [
  ];
  username: string ;

  /**
   * Constructor
   * @param platform not an idea
   * @param splashScreen loading
   * @param statusBar loading
   * @param storage to keep the user data
   * @param authenticationService for authentitacion
   * @param router for navigation
   * ----------------------------------------------------------------------------------------
   */
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private storage: Storage,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {
    this.initializeApp();
  }

  /**
   * ----------------------------------------------------------------------------------------
   */
  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();

      this.router.navigate(['login']);

      this.authenticationService.authenticationState.subscribe(state => {
        this.loginStatus = state;
        // console.log('STATE initalizeApp: ', state);
      });

      this.authenticationService.authenticationState.subscribe(state => {
        if (state === true) {
          this.router.navigate(['users', 'tasks']);
        }
      });

    });
  }

  addNew() {
    // TODO: agregar asignatura
  }

  logout() {
    this.storage.remove('currentUser', );
    this.router.navigateByUrl('/login');
    this.loginStatus = false;
  }

}

import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController, ToastController, NavController } from '@ionic/angular';
import { DataService } from 'src/app/services/data.service';


@Component({
  selector: 'app-mod-import',
  templateUrl: './mod-import.page.html',
  styleUrls: ['./mod-import.page.scss'],
})
export class ModImportPage implements OnInit {

  passedObj = null;
  activitiesList: any = [];
  message = '';
  btnImportarDisabled = false;
  fka = null;
  fkrel = null;

  /**
   * ------------------------------------------------------------------
   */
  constructor(
    private navController: NavController,
    private navParams: NavParams,
    private modalController: ModalController,
    private toastController: ToastController,
    private dataService: DataService
  ) { }

  /**
   * ------------------------------------------------------------------
   */
  ngOnInit() {
    this.passedObj = this.navParams.get('obj');
    // console.log('Objeto importacion', this.passedObj);
    this.loadData();
  }

  /**
   * ------------------------------------------------------------------
   */
  loadData() {
    // Cargamos las actividades
    const postData = { 'fk': this.passedObj.id }; //
    // console.log('Vamos a por la ', this.passedObj.id)
    this.dataService.postDataSubscribe('getimportdetails', postData).then((result) => {
      const objData = JSON.parse(JSON.stringify(result)); // TODO: Need review, specially adding error control
      if (objData.error) {
        this.message = objData.error; // Upsss! something goes wrong
      } else {
        this.activitiesList = objData.tasks; // Lista de las actividades
        this.fka = objData.fka; // Id de asignatura general
        this.fkrel = objData.fkrel; // Id de relacion usuario / asignatura
      }
    });
  }

  /**
   * ------------------------------------------------------------------
   */
  importarActividades() {
    // Cargamos las actividades
    const postData = { 'fka': this.fka, 'fkrel': this.fkrel, 'class': this.passedObj.uname }; //
    // console.log('Vamos a por la ', this.passedObj.id)
    this.dataService.postDataSubscribe('importtasks', postData).then((result) => {
      const objData = JSON.parse(JSON.stringify(result)); // TODO: Need review, specially adding error control
      if (objData.error) {
        this.message = objData.error; // Upsss! something goes wrong
      } else {
        this.btnImportarDisabled = true;
        this.message = objData.text;
        this.navController.navigateRoot(`/users/tasks`);
        this.closeModal();
      }
    });
  }

  /**
*
* @param msg Mensaje que mostramos en el aviso
* -----------------------------------------------------------
*/
  async presentToast(msg: string) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000,
      position: 'bottom',
      showCloseButton: true,
      closeButtonText: 'Cerrar',
      color: 'secondary'
    });
    toast.present();
  }

  /**
   * ------------------------------------------------------------------
   */
  closeModal() {
    this.modalController.dismiss();
  }

}

import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TasksPipe } from './pipes/tasks.pipe';

@NgModule({
  declarations: [TasksPipe],
  imports: [IonicModule],
  exports: [TasksPipe]
})
export class PipesModule {

}

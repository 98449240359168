import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tasksPipe'
})
export class TasksPipe implements PipeTransform {

  transform(value: Array<any>, swShowEnded: boolean ) {
    let swAll = true;
    let swActiveOk = true;

    const filteredItems = []; // Empty
    for ( let i = 0, fini = value.length; i < fini; i++ ) {

      swAll = ( swShowEnded === true ) ? true : false;
      if ( swShowEnded ) { swActiveOk = ( value[i].sw_done === 0 ); }

      if ( value[i].sw_done === 0 || swAll  ) { // Comprobamos las condiciones
        filteredItems.push( value[i] ); // Condition is ok
      }
    }

    // console.log('Condición:', swShowEnded, swActiveOk, ( swActiveOk || swAll  ) );

    return filteredItems;
  }

}
